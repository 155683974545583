<!-- Created by henian.xu on 2018/9/10. -->

<template>
    <page>
        <el-row>
            <el-col :span="12">
                <draggable v-model="myArray">
                    <transition-group>
                        <div
                            class="drag-item"
                            v-for="element in myArray"
                            :key="element.id"
                        >
                            {{ element.name }}
                        </div>
                    </transition-group>
                </draggable>
            </el-col>
            <el-col :span="12">
                <pre>{{ myArray }}</pre>
            </el-col>
        </el-row>

        <el-row class="ma-t">
            <el-col :span="12">
                <upload
                    action="/rs/attachment/uploadShopLevel"
                    draggable
                    :limit="8"
                    v-model="uploadList"
                />
            </el-col>
            <el-col :span="12">
                <pre>{{ uploadList }}</pre>
            </el-col>
        </el-row>
        <el-row class="ma-t">
            <el-col :span="12">
                <upload
                    action="/rs/attachment/uploadShopLevel"
                    draggable
                    :limit="8"
                    list-type="text"
                    v-model="uploadList"
                />
            </el-col>
            <el-col :span="12">
                <pre>{{ uploadList }}</pre>
            </el-col>
        </el-row>
    </page>
</template>

<script>
export default {
    name: 'BaiduMapPage',
    data() {
        return {
            uploadList: [
                {
                    name: '97e957bb845244418625b09aa68a9ef1.png',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/97e957bb845244418625b09aa68a9ef1.png',
                },
                {
                    name: 'ffa69b55e1924c519b1fda87e8477b7d.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/ffa69b55e1924c519b1fda87e8477b7d.jpg',
                },
                {
                    name: '31f6e6efec034e4697baa94f6242b933.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/31f6e6efec034e4697baa94f6242b933.jpg',
                },
                {
                    name: 'bfe0ce29a9db476b8a097d3d56100762.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/bfe0ce29a9db476b8a097d3d56100762.jpg',
                },
                {
                    name: '4bb238d7996e4c2b8313059140e974c7.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/4bb238d7996e4c2b8313059140e974c7.jpg',
                },
                {
                    name: 'a3e23739f07149c4a251a8e585a3741a.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/a3e23739f07149c4a251a8e585a3741a.jpg',
                },
            ],
            myArray: [
                {
                    id: 1,
                    name: 'vue',
                },
                {
                    id: 2,
                    name: 'react',
                },
                {
                    id: 3,
                    name: 'angular',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
pre {
    padding: $padding;
    background-color: $gray8;
    color: #fff;
    overflow: auto;
}
.drag-item {
    padding: $padding;
    background-color: $gray2;
    + .drag-item {
        margin-top: $margin;
    }
}
</style>
