<!-- Created by henian.xu on 2018/9/10. -->

<template>
    <page>
        <PageDecorator
            ref="pageDecorator"
            :json="json"
            :available-components="availableComponents"/>
        <el-button @click="onDecorationJson">
            onDecorationJson
        </el-button>

        <el-row>
            <el-col :span="12">
                <draggable v-model="myArray">
                    <transition-group>
                        <div
                            class="drag-item"
                            v-for="element in myArray"
                            :key="element.id"
                        >
                            {{ element.name }}
                        </div>
                    </transition-group>
                </draggable>
            </el-col>
            <el-col :span="12">
                <pre>{{ myArray }}</pre>
            </el-col>
        </el-row>
    </page>
</template>

<script>
const testJson =
    '[{"componentType":"GoodsList","componentMargin":0,"supportAd":true,"supportWaterfall":true,"props":{"type":"2","orderBy":"onSaleTime,asc","data":[],"adList":[{"src":"http://192.168.1.152:4333/upload/fileLibrary/2021/11/26/ff65227725594bfa86425762e62d6cb5.jpg","href":"","alt":"","label":"11","naturalWidth":1200,"naturalHeight":800},{"src":"http://192.168.1.152:4333/upload/fileLibrary/2021/10/08/b0fd0c6f3ef3426f8ffd876e189037ae.jpg","href":"","alt":"","label":"22","naturalWidth":null,"naturalHeight":null}]}},{"componentType":"Ad","componentMargin":0,"props":{"margin":0,"type":"2","delay":3000,"data":[{"src":"http://192.168.1.152:4333/upload/shop/ECCBC87E4B5CE2FE28308FD9F2A7BAF3/shopDecoration/d4ab514f3f0c4e7c89452fa2309b1b48.png","href":"","alt":"","naturalWidth":null,"naturalHeight":null},{"src":"http://192.168.1.152:4333/upload/shop/ECCBC87E4B5CE2FE28308FD9F2A7BAF3/shopDecoration/c691b17f968a44c6b804cfac7c84a897.jpg","href":"","alt":"","naturalWidth":null,"naturalHeight":null}]}},{"componentType":"Page","props":{"backgroundColor":"#f8f9fa","title":"页面标题"}}]';

export default {
    name: 'BaiduMapPage',
    data() {
        return {
            uploadList: [
                {
                    name: '97e957bb845244418625b09aa68a9ef1.png',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/97e957bb845244418625b09aa68a9ef1.png',
                },
                {
                    name: 'ffa69b55e1924c519b1fda87e8477b7d.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/ffa69b55e1924c519b1fda87e8477b7d.jpg',
                },
                {
                    name: '31f6e6efec034e4697baa94f6242b933.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/31f6e6efec034e4697baa94f6242b933.jpg',
                },
                {
                    name: 'bfe0ce29a9db476b8a097d3d56100762.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/bfe0ce29a9db476b8a097d3d56100762.jpg',
                },
                {
                    name: '4bb238d7996e4c2b8313059140e974c7.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/4bb238d7996e4c2b8313059140e974c7.jpg',
                },
                {
                    name: 'a3e23739f07149c4a251a8e585a3741a.jpg',
                    address: 'http://192.168.1.152:4333/upload/platform/shopLevel/a3e23739f07149c4a251a8e585a3741a.jpg',
                },
            ],
            myArray: [
                {
                    id: 1,
                    name: 'vue',
                },
                {
                    id: 2,
                    name: 'react',
                },
                {
                    id: 3,
                    name: 'angular',
                },
            ],
            json: testJson,
            availableComponents: [
                {
                    name: '题库',
                    data: {
                        componentType: 'Experience',
                        componentMargin: 0,
                        props: {
                            ids: [],
                            data: [],
                        },
                        control: [
                            {
                                label: '请选择题目',
                                formComponent: 'ExperiencePicker',
                                name: 'ids',
                            },
                        ],
                        handlerData(data) {
                            data.props.ids = data.props.data.reduce((prve, curr) => {
                                prve.push(curr.id);
                                return prve;
                            }, []);
                            data.props.data = [];
                            delete data.control;
                            if (!data.props.ids || !data.props.ids.length) return false;
                            return data;
                        },
                    },
                },
                {
                    name: '分校选择器',
                    data: {
                        componentType: 'CampusPicker',
                        componentMargin: 0,
                        props: {},
                    },
                },
                'Toolbar',
                'Goods',
                {
                    name: '商品列表',
                    data: {
                        componentType: 'GoodsList',
                        componentMargin: 0,
                        supportAd: true,
                        supportWaterfall: true,
                        props: {
                            type: '2',
                            orderBy: 'onSaleTime,asc',
                            data: [],
                            adList: [],
                        },
                        control: [],
                    },
                },
                'Cms',
                'CmsList',
            ],
        };
    },
    methods: {
        onDecorationJson() {
            console.log(this.$refs.pageDecorator.getDecorationJson());
        },
    },
    created() {
        this.$nextTick(() => {
            console.log(this.$options.components);
        });
    },
};
</script>

<style lang="scss" scoped>
pre {
    padding: $padding;
    background-color: $gray8;
    color: #fff;
    overflow: auto;
}
.drag-item {
    padding: $padding;
    background-color: $gray2;
    + .drag-item {
        margin-top: $margin;
    }
}
</style>
