<!-- Created by henian.xu on 2018/9/10. -->

<template>
    <page>
        <div
            class="body"
            slot="body"
        >
            <!--<Lodop
                ref="Lodop"
                v-model="runScript"
            >
            </Lodop>-->
            <el-button
                type="primary"
                @click="print"
            >
                打印预览
            </el-button>
        </div>
    </page>
</template>

<script>
import { ElementUI } from '@vmf/components';
import script from './lodopScript';

const runScript = `${script}console.log('打印测试',LODOP)`;

const { Loading } = ElementUI;

export default {
    name: 'LodopPage',
    data() {
        return {};
    },
    computed: {
        mapStyle() {
            return {
                width: '100%',
                height: '100%',
            };
        },
    },
    methods: {
        print() {
            const loadingInstance = Loading.service({
                text: '打印控件初始化...',
                lock: true,
            });
            this.$utils.Lodop.init(runScript).finally(() => {
                loadingInstance.close();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
